$platform: web;
@import '~normalize.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'mixins';
@import 'animate.css';

@font-face {
    font-family: Whyte-Book;
    src: url('./Whyte-Light.otf');

    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Whyte-Book;
    src: url('./Whyte-Book.otf');

    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Whyte-Book;
    src: url('./Whyte-Medium.otf');
    font-weight: bold;
    font-style: normal;
}

div {
    @include user-select(none);
    cursor: default;
}

button {
    cursor: pointer;
}

body,
html {
    margin: 0;
    height: 100%;
    font-family: 'Whyte-Book', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // ios
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;

    @media screen and (min-width: 2000px) {
        font-size: 150% !important;
    }

    @media screen and (min-width: 1050px) and (max-width: 1279px) {
        font-size: 90%;
    }

    @media screen and (min-width: $layout-breakpoint-width) and (max-width: 1049px) {
        font-size: 80%;
    }

    @media screen and (max-width: $layout-breakpoint-width - 1px) {
        font-size: 100% !important;
    }
}

button,
input,
keygen,
select,
textarea {
    font-family: 'Whyte-Book', sans-serif;
    font-size: initial;
}

button {
    @include user-select(none);
}

select {
    -webkit-appearance: menulist-button;
    -moz-appearance: menu-item;
}

button:focus,
input:focus {
    @include themify() {
        outline-width: $outline-width;
    }
}

svg {
    vertical-align: middle;
}

button[pex-button] {
    @include --pex-button-reset();
}

[hidden] {
    display: none !important;
}

.pex-text-input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #4a90e2;
    @include themify() {
        outline-width: $outline-width;
    }
    color: #4a90e2;
    font-size: 16px;
    font-weight: lighter;

    &--big {
        font-size: 19px;
    }
}

@if $platform == 'ios' {
    a*::-webkit-media-controls-panel {
        display: none !important;
        -webkit-appearance: none;
    }

    *::--webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
    }

    *::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
    }

    button {
        -webkit-user-select: none;
        user-select: none;
    }
}

.accessibility-headline {
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
    visibility: hidden;
    z-index: -1;
}
// for the embed images and videos in the chat messages

.ejs-embed {
    width: 100%;

    img {
        width: 100%;
    }

    iframe {
        width: 100%;
        height: 200px;
    }
}
@media screen and (max-width: $layout-breakpoint-width - 1px) {
    .pex-portrait-only {
        visibility: visible;
    }

    .pex-landscape-only {
        visibility: hidden;
    }
}
@media screen and (min-width: $layout-breakpoint-width) {
    .pex-portrait-only {
        visibility: hidden;
    }

    .pex-landscape-only {
        visibility: visible;
    }
}

.push-content-down {
    top: 90px !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    overflow: visible;
}

::-webkit-scrollbar-thumb {
    min-height: 18px;
    border-width: 1px 1px 1px 8px;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    background-clip: padding-box;
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
}

::-webkit-slider-thumb element .b-w::-webkit-scrollbar-track {
    border: solid transparent;
    border-width: 0 0 0 6px;
    background-clip: padding-box;
}

::-webkit-scrollbar-track {
    border: solid transparent;
    border-width: 0 0 0 6px;
    background-clip: padding-box;
}

::-webkit-scrollbar-corner {
    background: 0 0;
}

::-webkit-scrollbar-track-piece {
    background: #e6e6e6;
}

@import 'components/input';

// Buttons

.button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px 32px;
    padding-top: 16px;
    min-height: 44px;
    @media screen and (max-width: $layout-breakpoint-width - 1px) {
        // position: absolute;
        // left: 14px;
        // right: 14px;
        // bottom: 0;
        margin-top: auto;
        margin-bottom: 16px;
    }
}

.button-group--right {
    flex-direction: row-reverse;
}
@include placeholder-color(#aeaeae);

.dialog-button {
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    margin: 5px;
    padding: 10px 15px 11px;
    font-weight: normal;
    font-size: 1em;
    min-width: 100px;
    transition: background-color 0.5s ease, border-color 0.5s ease,
        width 0.5s ease;
    @include themify() {
        background-color: color(white);
        border-color: color(blue);
        color: color(blue);
    }
}

.small-button {
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    margin: 5px;
    padding: 5px 8px;
    font-weight: normal;
    font-size: 0.6em;
    min-width: 60px;
    transition: background-color 0.5s ease, border-color 0.5s ease,
        width 0.5s ease;
    @include themify() {
        background-color: color(white);
        border-color: color(blue);
        color: color(blue);
    }
}

.blue-action-button {
    @include themify() {
        background-color: color(blue);
        border-color: color(blue);
        color: color(white);
    }

    &:active {
        transform: scale(0.95);
    }
}

.green-action-button {
    @include themify() {
        background-color: color(green);
        border-color: color(green);
        color: color(white);
    }

    &:active {
        transform: scale(0.95);
    }
}

.red-action-button {
    @include themify() {
        background-color: color(red);
        border-color: color(red);
        color: color(white);
    }

    &:active {
        transform: scale(0.95);
    }
}

.gray-action-button {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: color(white);
}

.blue-non-action-button {
    @include themify() {
        border-color: color(blue);
        color: color(blue);
    }

    &:active {
        transform: scale(0.95);
    }
}

.green-non-action-button {
    @include themify() {
        border-color: color(green);
        color: color(green);
    }

    &:active {
        transform: scale(0.95);
    }
}

.red-non-action-button {
    @include themify() {
        border-color: color(red);
        color: color(red);
    }

    &:active {
        transform: scale(0.95);
    }
}

.gray-non-action-button {
    @include themify() {
        border-color: color(dark-gray);
        color: color(dark-gray);
    }
}

.circle-close-button {
    border: none;
    border-radius: 50%;
    background-color: #f2f2f2;
    opacity: 0.4;
    height: 36px;
    width: 36px;
    display: block;
    text-align: center;
    margin: auto;
    position: relative;

    &-icon {
        line-height: 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 24px;
        width: 24px;
    }
}

.close-button {
    transition: 0.5s ease-in-out all;
    transition-delay: 0.2s;
    padding: 7px 8px 9px;
    background-color: transparent;
    line-height: 24px;
    cursor: pointer;
    min-width: unset;
    margin: 0;
}
// Input Boxes

.dialog-box {
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    margin-right: 5px;
    padding: 5px 10px;
    font-weight: normal;
    font-size: 1.1em;
    @include themify() {
        border-color: color(blue);
        color: color(blue);
        background-color: color(white);
    }
}

.blue-dialog-box {
    @include themify() {
        border-color: color(blue);
        color: color(blue);
    }
}

.gray-dialog-box {
    border-color: #c4c4c4;
}

.green-dialog-box {
    @include themify() {
        border-color: color(green);
    }
}

.red-dialog-box {
    @include themify() {
        border-color: color(red);
    }
}

.bold-heading {
    font-size: 1.2em;
    font-weight: bold;
}
//dialog

.pex-dialog-close {
    float: right;
    margin: 15px;
    display: block;
}

.pex-dialog-error-code {
    float: right;
    color: #c3c3c3;
    font-size: 12px;
    margin: -20px 20px 15px;
}

.pex-dialog-content {
    &-inner {
        text-align: center;
        margin: 70px 30px 60px;
        box-sizing: border-box;
    }

    &-title {
        font-size: 24px;
        margin: 0 auto 40px;
    }

    &-message {
        margin: 40px auto;
        font-size: 16px;
        color: #4a4a4a;

        &--medium {
            max-width: 250px;
        }
    }

    &-button {
        font-size: 16px;
    }
}
@media screen and (max-width: 512px) {
    .pex-stage__toolbar-button {
        display: inline-block;
        position: relative;
        padding: 8px 3px 16px;
        // margin-right: -4px;

        .no-volume-bar & {
            padding-bottom: 10px;
        }
    }

    .pex-stage__toolbar-button-icon {
        height: 20px;
        width: 20px;
        vertical-align: middle;
    }
}
@media screen and (min-width: 512px) {
    .pex-stage__toolbar-button {
        display: inline-block;
        position: relative;
        padding: 10px 6px 16px;
        font-size: 24px;
        // margin-right: -4px;
        .no-volume-bar & {
            padding-bottom: 10px;
        }
    }

    .pex-stage__toolbar-button-icon {
        height: 28px;
        width: 28px;
        vertical-align: middle;
    }
}

.cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    // This works around a Chrome bug that can cause the tab to crash when large amounts of
    // non-English text get wrapped: https://bugs.chromium.org/p/chromium/issues/detail?id=1201444
    white-space: nowrap;

    // Avoid browsers rendering the focus ring in some cases.
    outline: 0;

    // Avoid some cases where the browser will still render the native controls (see #9049).
    -webkit-appearance: none;
    -moz-appearance: none;

    // We need at least one of top/bottom/left/right in order to prevent cases where the
    // absolute-positioned element is pushed down and can affect scrolling (see #24597).
    // `left` was chosen here, because it's the least likely to break overrides where the
    // element might have been positioned (e.g. `mat-checkbox`).
    left: 0;

    [dir='rtl'] & {
        left: auto;
        right: 0;
    }
}
